<template>
	<div class="message message--foreign">
		<div class="message__user">
			{{ name }}
		</div>
		<div class="message__contents">
			{{ contents }}
		</div>
		<div class="message__date">
			{{ date }}
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			name: ''
		};
	},
	props: {
		user: {
			type: String,
			required: true
		},
		date: {
			type: String,
			default: ''
		},
		contents: {
			type: String,
			default: ''
		}
	},
	created() {
		this.getUserName();
	},
	methods: {
		getUserName() {
			const opts = {
				model: 'operationsuserchat',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'usr',
							op: 'eq',
							data: this.user
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.name = response.data.data[0].name + ' (' + response.data.data[0].companyname + ')';
				}
			});
		}
	}
};
</script>
