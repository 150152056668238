export default {
	data() {
		return {
			dateTimeFormat: `DD/MM ${this.$store.getters.timeFormat}`,
			diffDateTimeFormat: `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`,
			icons: {
				shippingLine: {
					icon: 'fas fa-ship',
					desc: 'Shipping Line',
					color: '#B0B6C2'
				},
				portAuthority: {
					icon: 'fas fa-home-alt',
					desc: 'Port Authority',
					color: '#F1BCF2'
				},
				containerTerminal: {
					icon: 'fas fa-forklift',
					desc: 'Container Terminal',
					color: '#BADD8E'
				},
				warning: {
					icon: 'fas fa-exclamation',
					desc: 'Warning',
					color: '#E74C3C'
				},
				eventLog: {
					icon: 'fas fa-trophy',
					desc: 'Event Log',
					color: '#4FA439'
				},
				ais: {
					icon: 'fas fa-broadcast-tower',
					desc: 'AIS',
					color: '#F66F0E'
				},
				others: {
					icon: '',
					desc: 'Others'
				}
			}

		};
	},
	methods: {
		showAMPM(date) {
			if (!date) {
				return '';
			}
			return date + (date.split(' ')[1].substr(0, 2) < 12 ? ' am' : ' pm');
		}
	}
}
