<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<v-row dense>
				<v-col cols="6">
					<pui-checkbox
						:id="`solascert`"
						:label="$t('form.visitsolas.solascert')"
						v-model="model.solascert"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<pui-select
						:id="`solascertcompany`"
						:attach="`solascertcompany`"
						:label="$t('form.visitsolas.solascertcompany')"
						v-model="model"
						modelName="company"
						toplabel
						clearable
						:disabled="formDisabled"
						:itemsToSelect="itemsToSelectCertCompany"
						:modelFormMapping="{ id: 'solascertcompany' }"
						itemValue="id"
						itemText="name"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<pui-date-field
						:id="`solascertexpiration`"
						:label="$t('form.visitsolas.solascertexpiration')"
						v-model="model.solascertexpiration"
						toplabel
						:disabled="formDisabled"
						time
					></pui-date-field>
				</v-col>
				<v-col cols="6">
					<pui-text-field
						:id="`solaslevel-visitsolas`"
						v-model="model.solaslevel"
						:label="$t('form.visitsolas.solaslevel')"
						:disabled="formDisabled"
						toplabel
						maxlength="1"
					></pui-text-field>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<pui-checkbox
						:id="`solastenports`"
						:label="$t('form.visitsolas.solastenports')"
						v-model="model.solastenports"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
				<v-col cols="6">
					<pui-checkbox
						:id="`solassecurity`"
						:label="$t('form.visitsolas.solassecurity')"
						v-model="model.solassecurity"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'visitsolasform',
	components: {},
	data() {
		return {};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {},
	computed: {
		itemsToSelectCertCompany() {
			return [{ id: this.model.solascertcompany }];
		}
	},
	mounted() {}
};
</script>
