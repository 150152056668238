<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<v-row dense>
				<v-col cols="4">
					<pui-checkbox
						:id="`certisps`"
						:label="$t('form.visitisps.certisps')"
						v-model="model.certisps"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="2">
					<pui-number-field
						:id="`ispsnumber`"
						v-model="model.ispsnumber"
						:label="$t('form.visitisps.ispsnumber')"
						:disabled="formDisabled"
						toplabel
					></pui-number-field>
				</v-col>
				<v-col cols="6">
					<pui-select
						:id="`ispscompanyissue`"
						:attach="`ispscompanyissue`"
						:label="$t('form.visitisps.ispscompanyissue')"
						v-model="model"
						modelName="company"
						toplabel
						clearable
						:disabled="formDisabled"
						:itemsToSelect="itemsToSelectCompanyIssue"
						:modelFormMapping="{ id: 'ispscompanyissue' }"
						itemValue="id"
						itemText="name"
					></pui-select>
				</v-col>
				<v-col cols="4">
					<pui-date-field
						:id="`ispsexpiration`"
						:label="$t('form.visitisps.ispsexpiration')"
						v-model="model.ispsexpiration"
						toplabel
						:disabled="formDisabled"
						time
					></pui-date-field>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<pui-text-area
						:id="`ispsreasonsnocert`"
						v-model="model.ispsreasonsnocert"
						:label="$t('form.visitisps.ispsreasonsnocert')"
						:disabled="formDisabled"
						toplabel
						maxlength="100"
					></pui-text-area>
				</v-col>
			</v-row>
			<pui-field-set :title="$t('form.visitisps.location')">
				<v-row dense>
					<v-col cols="3">
						<pui-checkbox
							:id="`ispsisport`"
							:label="$t('form.visitisps.ispsisport')"
							v-model="model.ispsisport"
							:disabled="formDisabled"
						></pui-checkbox>
					</v-col>
					<v-col cols="9">
						<pui-select
							:id="`ispsportcode`"
							:attach="`ispsportcode`"
							:label="$t('form.visitisps.ispsportcode')"
							:disabled="formDisabled"
							toplabel
							clearable
							v-model="model"
							modelName="worldport"
							:modelFormMapping="{ code: 'ispsportcode' }"
							:itemsToSelect="itemsToSelectIspsPortCode"
							:itemValue="['code']"
							:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
							reactive
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-field
							:id="`ispslocation`"
							v-model="model.ispslocation"
							:label="$t('form.visitisps.ispslocation')"
							:disabled="formDisabled"
							toplabel
							maxlength="70"
						></pui-text-field>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('form.visitisps.ocpm')">
				<v-row dense>
					<v-col cols="8">
						<pui-text-field
							:id="`ispsocpmname`"
							v-model="model.ispsocpmname"
							:label="$t('form.visitisps.ispsocpmname')"
							:disabled="formDisabled"
							toplabel
							maxlength="70"
						></pui-text-field>
					</v-col>
					<v-col cols="4">
						<pui-text-field
							:id="`ispsocpmnumber`"
							v-model="model.ispsocpmnumber"
							:label="$t('form.visitisps.ispsocpmnumber')"
							:disabled="formDisabled"
							toplabel
							maxlength="20"
						></pui-text-field>
					</v-col>
				</v-row>
			</pui-field-set>
			<pui-field-set :title="$t('form.visitisps.ppb')">
				<v-row dense>
					<v-col cols="5">
						<pui-text-field
							:id="`ispsprotectionlevel`"
							v-model="model.ispsprotectionlevel"
							:label="$t('form.visitisps.ispsprotectionlevel')"
							:disabled="formDisabled"
							toplabel
							maxlength="1"
						></pui-text-field>
					</v-col>
					<v-col cols="7">
						<pui-checkbox
							:id="`ispsplanprotect`"
							:label="$t('form.visitisps.ispsplanprotect')"
							v-model="model.ispsplanprotect"
							:disabled="formDisabled"
						></pui-checkbox>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-checkbox
							:id="`ispsprocedure`"
							:label="$t('form.visitisps.ispsprocedure')"
							v-model="model.ispsprocedure"
							:disabled="formDisabled"
						></pui-checkbox>
					</v-col>
				</v-row>
				<pui-field-set>
					<v-row dense>
						<v-col cols="12">
							<v-row dense>
								<v-col cols="6">
									<v-row dense>
										<v-col cols="12">
											<pui-text-area
												:id="`ispsactivitynotprocedure`"
												v-model="model.ispsactivitynotprocedure"
												:label="$t('form.visitisps.ispsactivitynotprocedure')"
												:disabled="formDisabled"
												toplabel
												maxlength="70"
											></pui-text-area>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="6">
									<v-row dense>
										<v-col cols="12">
											<pui-date-field
												:id="`ispsstartnotprocedure`"
												:label="$t('form.visitisps.ispsstartnotprocedure')"
												v-model="model.ispsstartnotprocedure"
												toplabel
												:disabled="formDisabled"
												time
											></pui-date-field>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="12">
											<pui-date-field
												:id="`ispsendnotprocedure`"
												:label="$t('form.visitisps.ispsendnotprocedure')"
												v-model="model.ispsendnotprocedure"
												toplabel
												:disabled="formDisabled"
												time
											></pui-date-field>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-text-area
								:id="`ispsalternativemeasures`"
								v-model="model.ispsalternativemeasures"
								:label="$t('form.visitisps.ispsalternativemeasures')"
								:disabled="formDisabled"
								toplabel
								maxlength="140"
							></pui-text-area>
						</v-col>
					</v-row>
				</pui-field-set>

				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`ispsaditionalmeasures`"
							v-model="model.ispsaditionalmeasures"
							:label="$t('form.visitisps.ispsaditionalmeasures')"
							:disabled="formDisabled"
							toplabel
							maxlength="350"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`ispscomments`"
							v-model="model.ispscomments"
							:label="$t('form.visitisps.ispscomments')"
							:disabled="formDisabled"
							toplabel
							maxlength="350"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-checkbox
							:id="`ispslastfacilities`"
							:label="$t('form.visitisps.ispslastfacilities')"
							v-model="model.ispslastfacilities"
							:disabled="formDisabled"
						></pui-checkbox>
					</v-col>
				</v-row>
			</pui-field-set>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'visitispsform',
	components: {},
	data() {
		return {};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {},
	computed: {
		itemsToSelectCompanyIssue() {
			return [{ id: this.model.ispscompanyissue }];
		},
		itemsToSelectIspsPortCode() {
			return [{ code: this.model.ispsportcode }];
		}
	},
	mounted() {}
};
</script>
