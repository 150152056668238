<template>
	<div class="pui-chat pa-6">
		<section class="window">
			<section id="window__messages__container" class="window__messages__container">
				<slot>
					<pui-chat-messages-list :feed="feed" :user="user" class="messages-list" />
				</slot>
			</section>
			<div class="window__input__container">
				<slot name="input-container">
					<pui-chat-input-container @newOwnMessage="onNewOwnMessage" />
				</slot>
			</div>
		</section>
	</div>
</template>

<script>
import moment from 'moment';
import PuiChatMessagesList from './PuiChatMessagesList.vue';
import PuiChatInputContainer from './PuiChatInputContainer.vue';

export default {
	name: 'operations-chat',
	components: { PuiChatMessagesList, PuiChatInputContainer },
	props: {
		title: {
			type: String,
			default: 'Chat'
		},
		visit: {
			type: Object,
			required: true
		},
		initialFeed: {
			type: Array,
			default: function () {
				return [];
			}
		},
		newMessage: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data: function () {
		return {
			user: '',
			feed: []
		};
	},
	watch: {
		newMessage: function (newValue, oldValue) {
			this.pushToFeed(newValue);
			this.scrollToBottom();
		}
	},
	created() {
		this.user = this.$store.getters.getUser;

		this.touchChat();

		console.log(this.visit);
		if (this.visit.chat) {
			const dateTimeFormat = `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
			this.feed = this.visit.chat.map((obj) => {
				return {
					id: obj.id,
					user: obj.usr,
					contents: obj.message,
					date: this.$dateTimeUtils.getLocalFormattedDate(obj.datetime, dateTimeFormat)
				};
			});
		}
	},
	methods: {
		touchChat() {
			this.visit.chatLastCheck = this.visit.chatLastMessage;
			this.$puiRequests.getRequest(
				'/visit/touchChat',
				{ id: this.visit.id },
				(response) => {
					// do nothing
				},
				(error) => {
					console.error(error);
				}
			);
		},
		pushToFeed(element) {
			this.feed.push(element);
		},
		onNewOwnMessage(message) {
			const newOwnMessage = {
				id: this.feed.length + 1,
				user: this.user,
				contents: message,
				date: moment().format('HH:mm')
			};
			this.pushToFeed(newOwnMessage);
			this.scrollToBottom();
			this.addChatMessage(message);
		},
		addChatMessage(message) {
			this.$puiRequests.getRequest(
				'/visit/addChatMessage',
				{ id: this.visit.id, message: message },
				(response) => {
					// do nothing
				},
				(error) => {
					console.error(error);
				}
			);
		},
		scrollToBottom() {
			setTimeout(function () {
				var scrollContainer = document.getElementById('window__messages__container');
				var isScrolledToBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
				if (!isScrolledToBottom) {
					scrollContainer.scrollTop = scrollContainer.scrollHeight;
				}
			}, 201);
		}
	},
	watch: {
		visit(loaded) {
			console.log(this.visit.id);
			console.log(loaded);
			this.touchChat();

			if (this.visit.chat) {
				const dateTimeFormat = `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
				this.feed = this.visit.chat.map((obj) => {
					return {
						id: obj.id,
						user: obj.usr,
						contents: obj.message,
						date: this.$dateTimeUtils.getLocalFormattedDate(obj.datetime, dateTimeFormat)
					};
				});
			}
		}
	}
};
</script>

<style lang="postcss">
.pui-chat {
	.window {
		display: flex;
		flex-direction: column;

		box-shadow: 0 0 2px var(--primarycolor);
		max-width: 100%;
		border: 0px solid transparent;
		border-radius: 5px;
		overflow: hidden;

		&__header {
			&__container {
				padding: 10px 5px;
				font-weight: bold;
				box-shadow: 0 2px 2px -2px var(--primarycolor);
			}
		}

		&__messages {
			&__container {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				flex-grow: 999;
				overflow: auto;
				padding: 3px 0;
				box-shadow: 0 2px 2px -2px var(--primarycolor);
				transition: all 0.5s;
				overflow: scroll;
				overflow-x: hidden;

				&::-webkit-scrollbar {
					width: 0px; /* remove scrollbar space */
					background: transparent; /* optional: just make scrollbar invisible */
				}

				&::-webkit-scrollbar {
					width: 0;
				}

				&::-webkit-scrollbar-track {
				}

				&::-webkit-scrollbar-thumb {
					background-color: transparent;
				}
			}
		}

		.message {
			padding: 3px 5px;

			&--foreign {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;

				.message {
					&__user {
						display: flex;
						flex-direction: column;
						justify-content: center;
						font-weight: bold;
						margin-right: 5px;
					}

					&__contents {
						background-color: var(--B-10);
						border: 1px solid var(--B-10);
						border-radius: 12px;
						padding: 3px 5px;
						margin-right: 5px;
					}

					&__date {
						display: flex;
						flex-direction: column;
						justify-content: center;
						color: var(--N-100);
					}
				}
			}

			&--own {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				.message {
					&__date {
						display: flex;
						flex-direction: column;
						justify-content: center;
						color: var(--N-100);
						margin-right: 5px;
					}
					&__contents {
						color: whitesmoke;
						background-color: var(--primarycolor);
						border: 1px solid var(--primarycolor);
						border-radius: 12px;
						padding: 3px 5px;
					}
				}
			}
		}

		&__input {
			&__container {
				position: relative;
				bottom: 0;

				input[type='file'] {
					height: 0.1px !important;
					width: 0.1px !important;
					opacity: 0;
					overflow: hidden;
					position: absolute;
					z-index: -1;
				}

				input[type='text'] {
					font-size: 12px;
				}

				.input {
					&__container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						padding: 7px 5px;
						align-items: center;
					}

					&__field {
						flex-grow: 9999;
						padding: 5px 10px;
						border-radius: 12px;
						color: var(--N-0);
						background-color: var(--B-10);
						margin-right: 5px;

						input {
							border: none;
							background-color: inherit;
							width: 100%;

							&:focus {
								outline: none;
							}
						}
					}

					&__button {
						padding: 5px 10px;
						border-radius: 12px;
						color: var(--N-0);
						background-color: var(--primarycolor);
						font-weight: bold;
						cursor: pointer;
					}
				}
			}
		}
	}
}
</style>
