<template>
	<div class="input__button" @click="send">
		{{ $t('visit.chat.send') }}
	</div>
</template>

<script>
export default {
	methods: {
		send(event) {
			this.$emit('newOwnMessage');
		}
	}
};
</script>
