<template>
	<div class="message message--own">
		<div class="message__date">
			{{ date }}
		</div>
		<div class="message__contents">
			{{ contents }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		date: {
			type: String,
			default: ''
		},
		contents: {
			type: String,
			default: ''
		}
	}
};
</script>
