<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md4>
			<pui-field-set highlighted>
				<v-layout wrap>
					<v-flex xs12 md12 lg6 xl4>
						<pui-text-field
							:id="`imo-${modelName}`"
							:label="$t('form.ship.imo')"
							v-model="parentObject.imo"
							maxlength="12"
							toplabel
							required
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg6 xl8>
						<pui-text-field
							:id="`name-${modelName}`"
							:label="$t('form.ship.name')"
							v-model="parentObject.name"
							maxlength="100"
							toplabel
							required
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12 lg6 v-if="!this.isCreatingElement">
						<pui-date-field
							:id="`creationdatetime-${modelName}`"
							:label="$t('form.ship.creationdatetime')"
							v-model="parentObject.creationdatetime"
							:max="parentObject.downdatetime"
							toplabel
							required
							:disabled="!this.isCreatingElement"
						></pui-date-field>
					</v-flex>
					<v-flex xs12 md12 lg6 v-if="!this.isCreatingElement">
						<pui-date-field
							:id="`downdatetime-${modelName}`"
							:label="$t('form.ship.downdatetime')"
							v-model="parentObject.downdatetime"
							:min="parentObject.creationdatetime"
							toplabel
							disabled
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12 lg6>
						<pui-text-field
							:id="`callsign-${modelName}`"
							:label="$t('form.ship.callsign')"
							v-model="parentObject.callsign"
							maxlength="15"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md12 lg6>
						<pui-text-field
							:id="`mmsi-${modelName}`"
							:label="$t('form.ship.mmsi')"
							v-model="parentObject.mmsi"
							maxlength="50"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12>
						<pui-select
							id="country"
							attach="country"
							:label="$t('form.ship.flag')"
							toplabel
							clearable
							required
							:disabled="formDisabled"
							v-model="parentObject"
							modelName="country"
							:modelFormMapping="{ code: 'country' }"
							:itemsToSelect="[{ code: parentObject.country }]"
							itemValue="code"
							itemText="name"
							reactive
						>
						</pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md12>
						<pui-select
							id="type"
							attach="type"
							:label="$t('form.ship.type')"
							toplabel
							clearable
							required
							:disabled="formDisabled"
							v-model="parentObject"
							modelName="shiptypensw"
							:itemsToSelect="itemsToSelectShipType"
							:modelFormMapping="{ code: 'type' }"
							:itemValue="['code']"
							:itemText="(item) => `${item.description}`"
							:order="{ eta: 'asc' }"
							reactive
							:key="typeKey"
						></pui-select>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
		<v-flex xs12 md8>
			<pui-field-set :title="getTitleDataTecnic">
				<v-layout wrap>
					<v-flex xs12 md2 xl2>
						<pui-number-field
							:id="`gt-${modelName}`"
							:label="$t('form.ship.gt')"
							v-model="parentObject.gt"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							toplabel
							required
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md2 xl2>
						<pui-number-field
							:id="`length-${modelName}`"
							:label="$t('form.ship.length')"
							v-model="parentObject.length"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							required
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md2 xl2>
						<pui-number-field
							:id="`beam-${modelName}`"
							:label="$t('form.ship.beam')"
							v-model="parentObject.beam"
							maxlength="5"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							required
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md2 xl2>
						<pui-number-field
							:id="`draught-${modelName}`"
							:label="$t('form.ship.draught')"
							v-model="parentObject.draught"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl4>
						<pui-date-field
							:id="`builddatetime-${modelName}`"
							:label="$t('form.ship.builddatetime')"
							v-model="parentObject.builddatetime"
							toplabel
							:disabled="formDisabled"
						></pui-date-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`deadweighttonnage-${modelName}`"
							:label="$t('form.ship.deadweighttonnage')"
							v-model="parentObject.deadweighttonnage"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="ton"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`grossregisteredtonnage-${modelName}`"
							:label="$t('form.ship.grossregisteredtonnage')"
							v-model="parentObject.grossregisteredtonnage"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="ton"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`depth-${modelName}`"
							:label="$t('form.ship.depth')"
							v-model="parentObject.depth"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`riggingsheight-${modelName}`"
							:label="$t('form.ship.riggingsheight')"
							v-model="parentObject.riggingsheight"
							maxlength="5"
							decimals="2"
							min="0"
							max="99.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`power-${modelName}`"
							:label="$t('form.ship.power')"
							v-model="parentObject.power"
							maxlength="11"
							decimals="2"
							min="0"
							max="99999999.99"
							suffix="CV"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`cruisingspeed-${modelName}`"
							:label="$t('form.ship.cruisingspeed')"
							v-model="parentObject.cruisingspeed"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="n"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`maxspeed-${modelName}`"
							:label="$t('form.ship.maxspeed')"
							v-model="parentObject.maxspeed"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="n"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`sbtvolume-${modelName}`"
							:label="$t('form.ship.sbtvolume')"
							v-model="parentObject.sbtvolume"
							maxlength="9"
							decimals="3"
							min="0"
							max="99999.999"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-number-field
							:id="`sbtnumber-${modelName}`"
							:label="$t('form.ship.sbtnumber')"
							v-model="parentObject.sbtnumber"
							maxlength="9"
							decimals="3"
							min="0"
							max="99999.999"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
						<!-- <pui-select
							:id="`sbtnumber-${modelName}`"
							:attach="`sbtnumber-${modelName}`"
							:label="$t('form.ship.sbtnumber')"
							toplabel
							clearable
							:disabled="formDisabled"
							v-model="parentObject"
							:items="[
								{ name: 'MTQ', sbtunidades: 'MTQ' },
								{ name: 'TNE', sbtunidades: 'TNE' }
							]"
							:itemsToSelect="itemsToSelectSbtUnidades"
							:modelFormMapping="{ sbtunidades: 'sbtunidades' }"
							itemValue="sbtunidades"
							itemText="name"
							reactive
						></pui-select> -->
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-spinner-field
							:id="`propellernumber-${modelName}`"
							:label="$t('form.ship.propellernumber')"
							v-model.number="parentObject.propellernumber"
							maxlength="5"
							min="0"
							max="99999"
							toplabel
							:disabled="formDisabled"
						></pui-spinner-field>
					</v-flex>
					<v-flex xs12 md4 xl2>
						<pui-text-field
							:id="`propellerlocation-${modelName}`"
							:label="$t('form.ship.propellerlocation')"
							v-model="parentObject.propellerlocation"
							toplabel
							maxlength="70"
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md6 xl4>
						<pui-text-field
							:id="`ramp1-${modelName}`"
							:label="$t('form.ship.ramp1')"
							v-model="parentObject.ramp1"
							maxlength="50"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`scope-${modelName}`"
							:label="$t('form.ship.scope')"
							v-model="parentObject.rampa1alcance"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa1anchura-${modelName}`"
							:label="$t('form.ship.width')"
							v-model="parentObject.rampa1anchura"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md6 xl4>
						<pui-text-field
							:id="`ramp2-${modelName}`"
							:label="$t('form.ship.ramp2')"
							v-model="parentObject.ramp2"
							maxlength="50"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa2alcance-${modelName}`"
							:label="$t('form.ship.scope')"
							v-model="parentObject.rampa2alcance"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa2anchura-${modelName}`"
							:label="$t('form.ship.width')"
							v-model="parentObject.rampa2anchura"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md6 xl4>
						<pui-text-field
							:id="`ramp3-${modelName}`"
							:label="$t('form.ship.ramp3')"
							v-model="parentObject.ramp3"
							maxlength="50"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa3alcance-${modelName}`"
							:label="$t('form.ship.scope')"
							v-model="parentObject.rampa3alcance"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa3anchura-${modelName}`"
							:label="$t('form.ship.width')"
							v-model="parentObject.rampa3anchura"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md6 xl4>
						<pui-text-field
							:id="`ramp4-${modelName}`"
							:label="$t('form.ship.ramp4')"
							v-model="parentObject.ramp4"
							maxlength="50"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa4alcance-${modelName}`"
							:label="$t('form.ship.scope')"
							v-model="parentObject.rampa4alcance"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 xl1>
						<pui-number-field
							:id="`rampa4anchura-${modelName}`"
							:label="$t('form.ship.width')"
							v-model="parentObject.rampa4anchura"
							maxlength="6"
							decimals="2"
							min="0"
							max="999.99"
							suffix="m"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md4 lg2>
						<pui-number-field
							:id="`dirtyballast-${modelName}`"
							:label="$t('form.ship.dirtyballast')"
							v-model="parentObject.dirtyballast"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="m3"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 lg3>
						<pui-number-field
							:id="`sludgetank-${modelName}`"
							:label="$t('form.ship.sludgetank')"
							v-model="parentObject.sludgetank"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="m3"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md4 lg3>
						<pui-number-field
							:id="`oilwatertank-${modelName}`"
							:label="$t('form.ship.oilwatertank')"
							v-model="parentObject.oilwatertank"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="m3"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg2>
						<pui-number-field
							:id="`wastewater-${modelName}`"
							:label="$t('form.ship.wastewater')"
							v-model="parentObject.wastewater"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="m3"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
					<v-flex xs12 md3 lg2>
						<pui-number-field
							:id="`waste-${modelName}`"
							:label="$t('form.ship.waste')"
							v-model="parentObject.waste"
							maxlength="11"
							decimals="3"
							min="0"
							max="9999999.999"
							suffix="m3"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
		<v-flex xs12>
			<pui-field-set :title="getTitleMoreData">
				<v-layout wrap>
					<v-flex xs12 md6>
						<pui-select
							:id="`owner-${modelName}`"
							:attach="`owner-${modelName}`"
							:label="$t('form.ship.owner')"
							toplabel
							clearable
							:disabled="formDisabled"
							v-model="parentObject"
							modelName="company"
							:itemsToSelect="itemsToSelectOwner"
							:modelFormMapping="{ id: 'owner' }"
							:itemValue="['id']"
							itemText="name"
							:order="{ nombre: 'asc' }"
							showOnlyActives
							reactive
						></pui-select>
					</v-flex>
					<v-flex xs12 md6>
						<pui-select
							:id="`manager-${modelName}`"
							:attach="`manager-${modelName}`"
							:label="$t('form.ship.manager')"
							toplabel
							clearable
							:disabled="formDisabled"
							v-model="parentObject"
							modelName="company"
							:itemsToSelect="itemsToSelectManager"
							:modelFormMapping="{ id: 'manager' }"
							:itemValue="['id']"
							itemText="name"
							reactive
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md4>
						<pui-text-field
							:id="`classificationsociety-${modelName}`"
							:label="$t('form.ship.classificationsociety')"
							v-model="parentObject.classificationsociety"
							maxlength="100"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md4>
						<pui-text-field
							:id="`hullinsurance-${modelName}`"
							:label="$t('form.ship.hullinsurance')"
							v-model="parentObject.hullinsurance"
							maxlength="100"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md4>
						<pui-text-field
							:id="`piclub-${modelName}`"
							:label="$t('form.ship.piclub')"
							v-model="parentObject.piclub"
							maxlength="100"
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12>
						<pui-text-area
							:id="`comments-${modelName}`"
							:label="$t('form.ship.comments')"
							v-model="parentObject.comments"
							toplabel
							:disabled="formDisabled"
							maxlength="300"
						></pui-text-area>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'ship-form-main-tab',

	data() {
		return {
			countryKey: 0,
			typeKey: 0
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		getTitleDataTecnic() {
			return this.$t('form.ship.datatecnic');
		},
		getTitleMoreData() {
			return this.$t('form.ship.moredata');
		},
		itemsToSelectShipType() {
			return [{ code: this.parentObject.type }];
		},
		itemsToSelectSbtUnidades() {
			return [{ sbtunidades: this.parentObject.sbtnumber }];
		},
		itemsToSelectOwner() {
			return [{ id: this.parentObject.owner }];
		},
		itemsToSelectManager() {
			return [{ id: this.parentObject.manager }];
		}
	},
	mounted() {},
	watch: {}
};
</script>
