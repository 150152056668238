<template>
	<div class="input__field">
		<input
			:value="value"
			type="text"
			name="message"
			:aria-placeholder="$t('visit.chat.typemessage')"
			:placeholder="$t('visit.chat.typemessage')"
			autofocus
			@input="$emit('input', $event.target.value)"
			@keyup.enter.prevent="send"
		/>
		<br />
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: ''
		}
	},
	methods: {
		send(event) {
			this.$emit('newOwnMessage');
		}
	}
};
</script>
