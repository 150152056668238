<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<v-row dense>
				<v-col cols="3">
					<pui-checkbox
						:id="`dangoods`"
						:label="$t('form.visitmmpp.dangoods')"
						v-model="model.dangoods"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
				<v-col cols="3">
					<pui-checkbox
						:id="`loaddangoods`"
						:label="$t('form.visitmmpp.loaddangoods')"
						v-model="model.loaddangoods"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
				<v-col cols="3">
					<pui-checkbox
						:id="`unloaddangoods`"
						:label="$t('form.visitmmpp.unloaddangoods')"
						v-model="model.unloaddangoods"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
				<v-col cols="3">
					<pui-checkbox
						:id="`transitdangoods`"
						:label="$t('form.visitmmpp.transitdangoods')"
						v-model="model.transitdangoods"
						:disabled="formDisabled"
					></pui-checkbox>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'visitmmppform',
	components: {},
	data() {
		return {};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		modelLoaded: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {},
	computed: {},
	mounted() {}
};
</script>
