export default {
	methods: {
		getCurrentDate() {
			const today = new Date();
			const dd = (today.getDate() < 10 ? '0' : '') + today.getDate();
			const MM = (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1);
			const yyyy = today.getFullYear();
			return `${yyyy}-${MM}-${dd}`;
		},
		getCurrentDateLocal(withTime) {
			const today = new Date();
			const dd = (today.getDate() < 10 ? '0' : '') + today.getDate();
			const MM = (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1);
			const yyyy = today.getFullYear();

			if (withTime) {
				const hh = (today.getHours() < 10 ? '0' : '') + today.getHours();
				const mm = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
				return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
			}
			return `${dd}/${MM}/${yyyy}`;
		},
		getOnlyDateFromDateTime(dateTime) {
			return dateTime.substring(0, 10);
		},
		getOnlyHoursFromDateTime(dateTime) {
			return dateTime.substring(11, 13);
		},
		isLocalDateBefore(dateTime, dateTime2) {
			const dateLocal = this.getYearMonthDay(dateTime);
			const date2Local = this.getYearMonthDay(dateTime2);

			return this.$dateTimeUtils.isLocalDateBefore(dateLocal, date2Local);
		},
		isLocalDateAfter(dateTime, dateTime2) {
			const dateLocal = this.getYearMonthDay(dateTime);
			const date2Local = this.getYearMonthDay(dateTime2);

			return this.$dateTimeUtils.isLocalDateAfter(dateLocal, date2Local);
		},
		isLocalDateSameOrBefore(dateTime, dateTime2) {
			const dateLocal = this.getYearMonthDay(dateTime, true);
			const date2Local = this.getYearMonthDay(dateTime2, true);

			return this.$dateTimeUtils.isLocalDateSameOrBefore(dateLocal, date2Local);
		},
		isLocalDateSameOrAfter(dateTime, dateTime2) {
			const dateLocal = this.getYearMonthDay(dateTime, true);
			const date2Local = this.getYearMonthDay(dateTime2, true);

			return this.$dateTimeUtils.isLocalDateSameOrAfter(dateLocal, date2Local);
		},
		isLocalDateEqual(dateTime, dateTime2) {
			const dateLocal = this.getYearMonthDay(dateTime, true);
			const date2Local = this.getYearMonthDay(dateTime2, true);

			return this.$dateTimeUtils.isLocalDateEqual(dateLocal, date2Local);
		},
		getYearMonthDay(dateTime, withTime) {
			const [date, time] = dateTime.split(' ');
			const [dd, mm, yyyy] = date.split('/');

			if (withTime) {
				return `${yyyy}-${mm}-${dd} ${time}`;
			}
			return `${yyyy}-${mm}-${dd}`;
		}
	}
};
