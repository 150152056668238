<template>
	<div class="input__container">
		<slot name="input-field">
			<pui-chat-input-field v-model="message" @newOwnMessage="onNewOwnMessage"></pui-chat-input-field>
		</slot>
		<slot name="input-button">
			<pui-chat-input-button @newOwnMessage="onNewOwnMessage"></pui-chat-input-button>
		</slot>
	</div>
</template>

<script>
import PuiChatInputField from './PuiChatInputField.vue';
import PuiChatInputButton from './PuiChatInputButton.vue';

export default {
	components: { PuiChatInputField, PuiChatInputButton },
	data: function () {
		return {
			message: ''
		};
	},
	methods: {
		onNewOwnMessage() {
			if (!this.message || this.message === '') {
				return;
			}
			this.$emit('newOwnMessage', this.message);
			this.message = '';
		}
	}
};
</script>
