<template>
	<transition-group name="messages-list" tag="div" class="overflow-y-auto" style="height: 50vh">
		<div v-for="message in feed" :key="message.id" class="messages-list-item">
			<pui-chat-message-own v-if="message.user === user" :date="message.date" :contents="message.contents" />
			<pui-chat-message-foreign v-else :user="message.user" :date="message.date" :contents="message.contents" />
		</div>
	</transition-group>
</template>

<script>
import PuiChatMessageOwn from './PuiChatMessageOwn.vue';
import PuiChatMessageForeign from './PuiChatMessageForeign.vue';

export default {
	components: { PuiChatMessageOwn, PuiChatMessageForeign },
	props: {
		feed: {
			type: Array,
			default: function () {
				return [];
			}
		},
		user: {
			type: String,
			required: true
		}
	}
};
</script>
